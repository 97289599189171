import * as React from 'react';
import { useTranslation } from 'react-i18next';


import {FacebookShareButton, 
    FacebookIcon, TwitterShareButton,
    TwitterIcon, LinkedinShareButton,
     LinkedinIcon,
  EmailShareButton,
  EmailIcon} from 'react-share'

export default function SharingMenu({ percentage, wholeNumber, sharingText }) {
  
  const {t} = useTranslation();
  
  return (
    <div id="sharingMenu">
    <h3>{t('Dela din beräkning')}</h3>
        <FacebookShareButton url={`https://procentify.com?percent=${percentage}&number=${wholeNumber}`} quote={sharingText}><FacebookIcon/></FacebookShareButton>
        <TwitterShareButton url={`https://procentify.com?percent=${percentage}&number=${wholeNumber}`} title={sharingText}><TwitterIcon/></TwitterShareButton>
        <LinkedinShareButton url={`https://procentify.com?percent=${percentage}&number=${wholeNumber}`} summary={sharingText}><LinkedinIcon/></LinkedinShareButton>
        <EmailShareButton url={`https://procentify.com?percent=${percentage}&number=${wholeNumber}`} body={sharingText}><EmailIcon/></EmailShareButton>
    </div>
  );
}
