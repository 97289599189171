
import * as React from 'react';
import PercentageCalculator from './percentage';
import ProcentInfo from './procentinfo';
import { Link } from 'react-router-dom';
import { ReactComponent as SVG } from './learning.svg'
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

export default function MainPage({ title, description, background, fillValues, percentage, whole, children }) {

  const {t, i18n} = useTranslation();

  return (
   <div id="mainpage">
    <Helmet>
        <html lang={i18n.language} />      
        <title>{t('Procenträknare - Procentify | Enkel procenträkning')}</title>
        <link rel="canonical" href="https://procentify.com" />
        <link rel="alternate" hreflang="de" href="https://procentify.com/de" />
        <link rel="alternate" hreflang="se" href="https://procentify.com" />
        <link rel="alternate" hreflang="x-default" href="https://procentify.com" />

        <meta name="description" content="Enkel procenträknare med färdiga mallar för lön och kontantinsats. Räkna t.ex. ut 80 procent av lönen och 15 procent av två miljoner."/>
    </Helmet>
    <h1 id="titleh1">{t('Procenträknare')}</h1>
    <article>
      <section>
        <div id="calculatorContainer">
        <PercentageCalculator className="calculatorContainer" />
        <SVG className="svg2"></SVG>
      </div>
      </section>
      <section>
        <ProcentInfo id="info"/>
      </section>
    </article>
    <footer>
    {/* <Link to="/privacy" style={{ color: 'inherit'}}>Integritetspolicy</Link> */}
      <Link to={ `/${i18n.language}/about`} style={{ color: 'inherit'}}>{t('about')}</Link>
    </footer>
    </div>
  );
}
