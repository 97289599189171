import './App.css';
import { Routes, Route } from "react-router-dom";
import MainPage from './mainpage';
import AboutUs from './about-procentify';

function App() {

  return (
    <div className="App">
       <Routes>
        <Route path="/" element={<MainPage />} />
        {/* <Route path="privacy" element={<Privacy/>} /> */}
        <Route path="/se/about" element={<AboutUs/>} />
        <Route path="/de" element={<MainPage />} />
        {/* <Route path="privacy" element={<Privacy/>} /> */}
        <Route path="/de/about" element={<AboutUs/>} />
      </Routes>
    </div>
  );
}

export default App;
