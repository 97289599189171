import { useTranslation } from "react-i18next";



export default function ProcentInfo({ title, description, background, fillValues, percentage, whole, children }) {

  const {t} = useTranslation();

  return (
    <div className="info">
        <hr></hr>
    <h2>{t('Hur räknar man ut procent?')}</h2> 
    <hr></hr>
    <h3>{t('Vad är procent?')}</h3>
    <p>{t('Procent betyder "av hundra" och när man till exempel räknar ut hur mycket 80 procent av lönen är så delar man egentligen lönen i hundra delar. Varje del är då en procent (en "av hundra"). Lägger man ihop 80 sådana delar får man 80 procent av lönen.')}</p> 
       <p>{t('Likadant är det att räkna ut 15 % av två miljoner. Då delar man två miljoner i 100 delar. 15 sådana delar är 15 procent av två miljoner.')}</p> 
    <h3>{t('Räkna ut procent av en summa')}</h3>
    <p>{t('Vi tar exemplet med lönen ovan. Säg att du ska räkna ut 80 % av en lön på 30 000. Såhär gör du:')}
      <ol id="listInstructions">
      <li>{t('Dela först 30 000 kr i hundra delar: 30 000/100')}</li>
      <li>{t('Då får du fram 1 % av lönen. Det är 300 kr.')}</li>
      <li>{t('Sen tar du 300 kr gånger 80 för att få 80 %. Det blir 80*300 = 24 000')}</li>
      </ol>
      {t('80 % av en lön på 30 000 är alltså 24 000.')}
      </p>
    <h3>{t('Det går snabbare med decimaltal')}</h3>

    <p>{t('Det går att uttrycka procent (som ju betyder "av hundra") på ett annat sätt också: med decimaltal. Genom att använda decimaltal kan man enkelt räkna ut procent på miniräknaren.')}</p>
      <p>{t('I ett decimaltal som 0,53 kallas positionen där 5:an står, för tiondel. Positionen där 3:an står kallas för hundradel. Tillsammans blir de hundradelen "53". 0,53 är alltså 53 hundradelar. Det är samma sak som 53 procent eftersom procent också är hundradelar.')}</p>
        <p><li>{t('0,03 är alltså 3 hundradelar och samma som 3 procent.')}</li></p>
        <p><li>{t('0,09 är 9 hundradelar och samma som 9 procent.')}</li></p>
        <p><li>{t('0,80 är 80 hundradelar och samma som 80 procent.')}</li></p>

        <p>{t('Genom att använda decimaltal när vi räknar ut procent kan vi göra beräkningen enklare. För att få fram 80 procent av lönen tar vi då lönen, som i vårt exempel var 30 000, och gångrar den med 0,80 (alltså med 80 procent).')}</p>
        <p>{t('Då får vi den här beräkningen för att räkna ut 80 % av summan 30 000:')}</p>
        <div id="final_calculation"><p>0,80 * 30 000 = 24 000</p></div>
        <h3>{t('Hur räknar man ut procent av en summa på miniräknaren?')}</h3>
        <p>{t('Använd dig av beräkningen ovan med decimaltal:')}</p>
        <p><li>{t('För att räkna ut 80 procent av 30 000 skriver du 0,80* 30 000 på miniräknaren. Svaret ska bli 24 000.')}</li></p>
        <p><li>{t('För att räkna ut 1 procent av en miljon skriver du 0,01 * 1 000 000 på miniräknaren. Svaret ska bli 10 000.')}</li></p>
        <p>{t('Du kan också använda vår procenträknare ovan för att få fram svaret utan att behöva hämta miniräknaren.')}</p>
    </div>
  );
}


/*
vad är procent
räkna ut procent
procent räknare
räkna procent
*/