
import { Link } from 'react-router-dom';
import {ReactComponent as SVGExplain } from './product_explain.svg'
import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';

export default function AboutUs() {

  const {t, i18n} = useTranslation();

  return (
    <div className="info">
    <Helmet>
        <html lang={i18n.language} />
        <meta charSet="utf-8" />
        <title>{t('Procenträknare - Procentify | Om oss')}</title>
        <link rel="canonical" href="https://procentify.com/se/about" />
        <link rel="alternate" hreflang="de" href="https://procentify.com/de/about" />
        <link rel="alternate" hreflang="se" href="https://procentify.com/se/about" />
        <link rel="alternate" hreflang="x-default" href="https://procentify.com/se/about" />
        <meta name="description" content="Procentify hjälper dig att räkna ut procent på ett enkelt och smidigt sätt. Räkna ut procent av en summa eller läs om hur du själv kan räkna procent."
    />
    </Helmet>
    <h1>{t('Om oss')}</h1> 
    <SVGExplain className="svg" />
    <p>{t('Procentify hjälper dig att räkna ut procent på ett enkelt och smidigt sätt.')}</p>  
    <p>{t('Du kan antingen använda vår ')}<Link to="/" style={{ color: 'inherit'}}>{t('procenträknare')}</Link>{t(' för att räkna ut procent av vilken summa som helst, eller våra färdiga mallar för att räkna ut vanliga procenträkningar du stöter på i vardagen.')}</p>

    <h2>{t('Vilka mallar finns?')}</h2>
    <p>{t('Procentify har just nu mallar för:')}
    <p>{t('Att räkna ut kontantinsats för att köpa en bostad för 2 miljoner eller 3 miljoner. Men du kan enkelt välja en sådan beräkning och skriva en annan köpsumma, till exempel 2,5 miljoner.')}</p> </p>
    <p>{t('Att räkna ut sjuklönen du får när du är hemma från jobbet, alltså 80 % av din lön.')}</p>
    <p>{t('Vi jobbar på att skapa fler mallar, så håll utik!')}</p>
    <h2>{t('Lär dig räkna procent själv')}</h2>
    <p>{t('Vi hjälper dig även att förstå vad procenträkning är för något och hur du själv kan räkna ut procent med hjälp av en miniräknare eller huvudräkning.')}</p>
    <p>{t('Målet är att förklara procenträkning på ett så enkelt sätt som möjligt. Det är inte så svårt om man bara förstår grunderna.')}</p>
    <h2>{t('Vem har skapat Procentify?')}</h2>
    <p>{t('Procentify har skapats av utvecklaren Elin Fritiofsson')}</p>
    { i18n.language === "se" ?
        <Link to="/" style={{ color: 'inherit'}}>{t('Till procenträknaren')}</Link>
        : <Link to={ `/${i18n.language}`} style={{ color: 'inherit'}}>{t('Till procenträknaren')}</Link>
    }
    </div>
  );
}
