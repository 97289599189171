
import './App.css';
import {useEffect, useState} from 'react'
import {
  multiply
} from 'mathjs'
import { useSearchParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import OutlinedCard from './card';
import Grid from '@mui/material/Unstable_Grid2';
import HouseIcon from '@mui/icons-material/House';
import PaidIcon from '@mui/icons-material/Paid';
import SharingMenu from './sharingMenu'
import NumericFormat from 'react-number-format'
import { useTranslation } from 'react-i18next';

function PercentageCalculator() {

  const {t, i18n} = useTranslation();

  let [result, setResult] = useState(0);
  let [newPercentage, setNewPercentage] = useState('');
  let [newWholeNumber, setNewWholeNumber] = useState('');
  let [middlePhrase, setMiddlePhrase] = useState(t('av talet'));

  const [searchParams] = useSearchParams();


  useEffect(()=> {
    const result = multiply(newWholeNumber ? newWholeNumber : 0, ((newPercentage ? newPercentage : 0)/100))
    setResult(result.toLocaleString('sv'));
  }, [newPercentage, newWholeNumber]);

  useEffect(()=> {
    const percentParam =searchParams.get("percent");
    const numberParam = searchParams.get("number");

    if(percentParam && numberParam){
      fillValues(Number(percentParam), Number(numberParam));
      return;
    }

    if(percentParam)
      setNewPercentage(Number(percentParam));
    if(numberParam)
      setNewWholeNumber(Number(numberParam));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fillValues = (percentage, whole, middlePhrase) => {
    setNewPercentage(percentage);
    setNewWholeNumber(whole);
    setMiddlePhrase(middlePhrase)
  }

  const getSharingText = ()=> {

    const percentageInput = document.getElementById("percent");
    const wholeNumberInput = document.getElementById("wholenum");

    let percentageValue = 0;
    let wholeNumberValue = 0;

    if(percentageInput)
      percentageValue = percentageInput.value

    if(wholeNumberInput)
      wholeNumberValue = wholeNumberInput.value;

    if(percentageValue === '' && wholeNumberValue === '')
    {
      const sharingString = t(`Procenträkning med enkla mallar.`);
      return sharingString;
    }

    const sharingString = `${percentageValue ? percentageValue : 0 } ${t('av')} ${wholeNumberValue ? wholeNumberValue : 0} = ${result}.`
    return sharingString;
  }

  return (
    <div>
<Box sx={{ m: "5rem"}}/>
      <Stack spacing={3}>
        <Box
      sx={{
        display: 'inline-grid',
        alignItems: 'center',
        '& > :not(style)': { m: 1 },
      }}
    >

  <NumericFormat decimalSeparator="," thousandSeparator=" " value={newPercentage} id="percent" placeholder="%" inputProps={{ style: { color: "white", textAlign: 'center', caretColor: 'white'}}} suffix={' %'} customInput={TextField} onValueChange={(value) => { setNewPercentage(value.floatValue)}} />
      <Typography variant="body1" component="body1">
          {middlePhrase}
        </Typography>
      <NumericFormat decimalSeparator="," thousandSeparator=" " value={newWholeNumber} id="wholenum" inputProps={{ style: { color: "white", textAlign: 'center', caretColor: 'white'}}} customInput={TextField} onValueChange={(value) => { setNewWholeNumber(value.floatValue)}} />
    </Box>
        <Typography variant="h6" component="h6">
          {t('Svar')}: {result}
        </Typography>
      </Stack>
     
      <div>
        <br /><br />
      <h3>
        {t('Börja från en vanlig beräkning:')}
        </h3>
        <br /> 
      <Grid container spacing={2} columns={16}>
      <Grid xs={16}>
        <OutlinedCard title={t("15 procent av 2 miljoner")} fillValues={fillValues} description={t("Räkna ut hur mycket kontantinsats du behöver för att köpa en bostad för två miljoner.")} background="#B8336A" percentage={i18n.language === "se" ? "15" : "20"} whole={i18n.language === "se" ? "2000000" : "200000"} middlePhrase={t("av bostadens pris")}><HouseIcon fontSize="large" /></OutlinedCard>
      </Grid>
      <Grid xs={16}>
        <OutlinedCard title={t("80 procent av lönen")} fillValues={fillValues} description={t("Räkna ut hur mycket sjuklön du får")} background="rgba(103, 58, 183, 0.85)" percentage={i18n.language === "se" ? "80" : "20"} whole={i18n.language === "se" ? "20000" : "50"} middlePhrase={i18n.language === "se" ? t("av lönen") : t("av talet")}><PaidIcon/></OutlinedCard>
      </Grid>
      <Grid xs={16}>
        <OutlinedCard title={t("15 procent av 3 miljoner")} fillValues={fillValues} description={t("Räkna ut hur mycket kontantinsats du behöver för att köpa en bostad för tre miljoner.")} background="#06A77D"  percentage={i18n.language === "se" ? "15" : "20"} whole={i18n.language === "se" ? "3000000" : "300000"} middlePhrase={t("av bostadens pris")} ><HouseIcon fontSize="large"/></OutlinedCard>
      </Grid>
      <Grid xs={16}>
        <OutlinedCard title="Procent av en summa"fillValues={fillValues} description={t("Räkna ut hur mycket ett visst antal procent av ett tal blir")} background="#7f7f7f" percentage="10" whole="100" middlePhrase={t("av talet")}><PaidIcon/></OutlinedCard>
      </Grid>
    </Grid>
        {/* <FolderList></FolderList> */}
        </div>
    <Box />
    <SharingMenu sharingText={getSharingText()} percentage={newPercentage} wholeNumber={newWholeNumber} />
   

    </div>
  );
}

export default PercentageCalculator;
