import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  se: {
    translation: {
      "Procenträknare": "Procenträknare",
      "about" : "Om procentify"
    }
  },
  de: {
    translation: {
      "Procenträknare": "Prozentrechner",
      "procenträknare": "Prozentrechner",
      "about" : "Über procentify",
      "Till procenträknaren": "Zum Prozentrechner",
      "Om oss": "Über uns",
      "Procenträknare - Procentify | Enkel procenträkning": "Prozentrechner – Procentify | Einfache Prozentrechnung",
      "Procenträknare - Procentify | Om oss": "Prozentrechner – Procentify | Über uns",
      "Hur räknar man ut procent?" : "Wie rechnet man mit Prozent?",
      "Svar": "Ergebnis",
      "av talet": "von dem Wert",
      "Börja från en vanlig beräkning:": "Von einer häufigen Berechnung ausgehen",
      "15 procent av 2 miljoner": "20 Prozent von 200.000",
      "80 procent av lönen": "20 Prozent von 50 euro",
      "15 procent av 3 miljoner": "20 Prozent von 300.000",
      "Procent av en summa": "Prozent von einer Summe",
      "av lönen": "vom Gehalt",
      "av bostadens pris": "vom Wert des Hauses",
      "Räkna ut hur mycket kontantinsats du behöver för att köpa en bostad för två miljoner.": "Berechne wie viel Eigenkapital Sie brauchen um ein Haus für 200.000 zu kaufen.",
      "Räkna ut hur mycket kontantinsats du behöver för att köpa en bostad för tre miljoner.": "Berechne wie viel Eigenkapital Sie brauchen um ein Haus für 300.000 zu kaufen.",
      "Räkna ut hur mycket sjuklön du får": "Berechne wie viel 20 % von 50 Euro sind",
      "Räkna ut hur mycket ett visst antal procent av ett tal blir": "Berechne Prozent von einer Summe",
      "Dela din beräkning": "Teile Ihre Berechnung",
      "av": "von",
      "Procenträkning med enkla mallar.": "Prozentrechnung mit einfachen Muster",
      // procent info:
      "Hur räknar man ut procent?": "Wie berechnet man Prozente?",
      "Vad är procent?": "Was sind Prozente?",
      "Procent betyder \"av hundra\" och när man till exempel räknar ut hur mycket 80 procent av lönen är så delar man egentligen lönen i hundra delar. Varje del är då en procent (en \"av hundra\"). Lägger man ihop 80 sådana delar får man 80 procent av lönen.": "Prozent bedeutet \"von hundert\" und wenn man zum Beispiel berechnet, wie viel 80 Prozent des Gehalts sind, teilt man das Gehalt tatsächlich in hundert Teile. Jeder Teil ist dann ein Prozent (einer \"von hundert\"). Wenn man 80 solcher Teile addiert, erhält man 80 Prozent des Gehalts.",
      "Likadant är det att räkna ut 15 % av två miljoner. Då delar man två miljoner i 100 delar. 15 sådana delar är 15 procent av två miljoner.": "Gleiches gilt für die Berechnung von 15 % von zwei Millionen. Dann teilt man zwei Millionen in 100 Teile. 15 solcher Teile sind 15 Prozent von zwei Millionen.",
      "Räkna ut procent av en summa": "Prozentuale Berechnung eines Betrags",
      "Vi tar exemplet med lönen ovan. Säg att du ska räkna ut 80 % av en lön på 30 000. Såhär gör du:": "Wir nehmen das oben genannte Gehaltsbeispiel. Angenommen, Sie möchten 80 % eines Gehalts von 30.000 berechnen. So gehen Sie vor:",
      "Dela först 30 000 kr i hundra delar: 30 000/100": "Teile zuerst 30.000 SEK in hundert Teile: 30.000/100",
      "Då får du fram 1 % av lönen. Det är 300 kr.": "Dann erhalten Sie 1 % des Gehalts. Das sind 300 SEK.",
      "Sen tar du 300 kr gånger 80 för att få 80 %. Det blir 80*300 = 24 000": "Dann multiplizieren Sie 300 SEK mit 80, um 80 % zu erhalten. Das ergibt 80*300 = 24.000",
      "80 % av en lön på 30 000 är alltså 24 000.": "80 % eines Gehalts von 30.000 SEK sind also 24.000 SEK.",
      "Det går snabbare med decimaltal": "Es geht schneller mit Dezimalzahlen.",
      "Det går att uttrycka procent (som ju betyder \"av hundra\") på ett annat sätt också: med decimaltal. Genom att använda decimaltal kan man enkelt räkna ut procent på miniräknaren.": "Prozente (die ja \"von hundert\" bedeuten) können auch anders ausgedrückt werden: mit Dezimalzahlen. Mit Dezimalzahlen kann man Prozente einfach auf dem Taschenrechner berechnen.",
      "I ett decimaltal som 0,53 kallas positionen där 5:an står, för tiondel. Positionen där 3:an står kallas för hundradel. Tillsammans blir de hundradelen \"53\". 0,53 är alltså 53 hundradelar. Det är samma sak som 53 procent eftersom procent också är hundradelar.": "In einer Dezimalzahl wie 0,53 wird die Position, an der sich die 5 befindet, als Zehntel bezeichnet. Die Position, an der sich die 3 befindet, wird als Hundertstel bezeichnet. Zusammen ergeben sie das Hundertstel \"53\". 0,53 sind also 53 Hundertstel. Das entspricht 53 Prozent, da Prozent auch Hundertstel sind.",
      "0,03 är alltså 3 hundradelar och samma som 3 procent.": "0,03 sind also 3 Hundertstel und dasselbe wie 3 Prozent.",
      "0,09 är 9 hundradelar och samma som 9 procent.": "0,09 sind also 9 Hundertstel und dasselbe wie 9 Prozent.",
      "0,80 är 80 hundradelar och samma som 80 procent.": "0,80 sind also 80 Hundertstel und dasselbe wie 80 Prozent.",
      "Genom att använda decimaltal när vi räknar ut procent kan vi göra beräkningen enklare. För att få fram 80 procent av lönen tar vi då lönen, som i vårt exempel var 30 000, och gångrar den med 0,80 (alltså med 80 procent).": "Durch die Verwendung von Dezimalzahlen bei der Berechnung von Prozenten können wir die Berechnung vereinfachen. Um 80 Prozent des Gehalts zu erhalten, multiplizieren wir das Gehalt, das in unserem Beispiel 30.000 war, mit 0,80 (also mit 80 Prozent).",
      "Då får vi den här beräkningen för att räkna ut 80 % av summan 30 000:": "Dann erhalten wir diese Berechnung, um 80% der Summe von 30.000 zu berechnen:",
      "0,80 * 30 000 = 24 000": "0,80 * 30.000 = 24.000",
      "Hur räknar man ut procent av en summa på miniräknaren?": "Wie berechnet man Prozente einer Summe auf einem Taschenrechner?",
      "Använd dig av beräkningen ovan med decimaltal:": "Verwenden Sie die obige Berechnung mit Dezimalzahlen:",
      "För att räkna ut 80 procent av 30 000 skriver du 0,80* 30 000 på miniräknaren. Svaret ska bli 24 000.": "Um 80 Prozent von 30.000 zu berechnen, geben Sie 0,80 * 30.000 auf dem Taschenrechner ein. Das Ergebnis sollte 24.000 sein.",
      "För att räkna ut 1 procent av en miljon skriver du 0,01 * 1 000 000 på miniräknaren. Svaret ska bli 10 000.": "Um 1 Prozent von einer Million zu berechnen, geben Sie 0,01 * 1.000.000 auf dem Taschenrechner ein. Das Ergebnis sollte 10.000 sein.",
      "Du kan också använda vår procenträknare ovan för att få fram svaret utan att behöva hämta miniräknaren.": "Sie können auch unseren Prozentrechner oben verwenden, um die Antwort zu erhalten, ohne den Taschenrechner zu verwenden.",
      // about us:
      "Procentify hjälper dig att räkna ut procent på ett enkelt och smidigt sätt.": "Procentify hilft Ihnen, Prozente auf einfache und bequeme Weise zu berechnen.",
      "Du kan antingen använda vår ": "Sie können entweder unseren ",
      " för att räkna ut procent av vilken summa som helst, eller våra färdiga mallar för att räkna ut vanliga procenträkningar du stöter på i vardagen.": " verwenden, um Prozentsätze von beliebigen Beträgen zu berechnen, oder unsere vorgefertigten Vorlagen, um gängige Prozentrechnungen zu berechnen, auf die Sie im Alltag stoßen.",
      "Vilka mallar finns?": "Welche Vorlagen gibt es?",
      "Procentify har just nu mallar för:": "Procentify hat derzeit Vorlagen für:",
      "Att räkna ut kontantinsats för att köpa en bostad för 2 miljoner eller 3 miljoner. Men du kan enkelt välja en sådan beräkning och skriva en annan köpsumma, till exempel 2,5 miljoner.": "Die Berechnung des Eigenkapitals für den Kauf einer Immobilie für 200.000 oder 300.000 Euro. Sie können jedoch einfach eine solche Berechnung auswählen und einen anderen Kaufbetrag angeben, z. B. 250.000 Euro",
      "Att räkna ut sjuklönen du får när du är hemma från jobbet, alltså 80 % av din lön.": "Berechnen Sie Rabatte, wie zum Beispiel 20 Prozent von 50 Euro",
      "Vi jobbar på att skapa fler mallar, så håll utik!": "Wir arbeiten daran, mehr Vorlagen zu erstellen, also bleiben Sie dran!",
      "Lär dig räkna procent själv": "Lernen Sie selbst Prozentrechnen",
      "Vi hjälper dig även att förstå vad procenträkning är för något och hur du själv kan räkna ut procent med hjälp av en miniräknare eller huvudräkning.": "Wir helfen Ihnen auch zu verstehen, was Prozentrechnung ist und wie Sie selbst Prozent mit einem Taschenrechner oder im Kopf berechnen können.",
      "Målet är att förklara procenträkning på ett så enkelt sätt som möjligt. Det är inte så svårt om man bara förstår grunderna.": "Das Ziel ist es, Prozentrechnung so einfach wie möglich zu erklären. Es ist nicht so schwierig, wenn man die Grundlagen versteht.",
      "Vem har skapat Procentify?": "Wer hat Procentify erstellt?",
      "Procentify har skapats av utvecklaren Elin Fritiofsson": "Procentify wurde von der Entwicklerin Elin Fritiofsson erstellt"
    }
  }
};

const options = {
     // order and from where user language should be detected
  order: ['path'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: options,
    resources,
    fallbackLng: "se", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;