import {Fragment} from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CardActionArea from '@mui/material/CardActionArea';
import { useTranslation } from 'react-i18next';


export default function OutlinedCard({ title, description, background, fillValues, percentage, whole, middlePhrase, children }) {

  const {t} = useTranslation();

  return (

      <Card variant="outlined" style={ {backgroundColor: background, color: 'white', borderRadius: '10px'} }>
          <Fragment>
          <CardActionArea onClick={() => {fillValues(percentage,whole, middlePhrase)}}>
    <CardContent  style={ {color: 'white'}}>
    <ListItem >
        <ListItemAvatar>
          <Avatar  style={ {backgroundColor: 'white', color: background}}>
            {children}
          </Avatar>
        </ListItemAvatar>
        <ListItemText disableTypography primary={<Typography type="body2" style={{ color: '#FFFFFF' }}>{t(title)}</Typography>} secondary={t(description)} />
      </ListItem>
    </CardContent>
  </CardActionArea>
  </Fragment>
      </Card>

  );
}
